import {AdminWidget} from "../../widget/AdminWidget";
import {LandingData} from "../../utils/data.util";
import {Link} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {axiosConfig} from "../../configs/axiosConfig";
import {toast} from "react-hot-toast";
import {GlobalContext} from "../../Context/GlobalContext";
import {handleDownloadFile} from "../../utils/HandleDownload";
import SpinnerLoading from "../../components/loadings/SpinnerLoading";

const Profile = () => {
    const {functionContext} = useContext(GlobalContext);
    const {fetchProfileData} = functionContext;
    const [profileDetail, setProfileDetail] = useState();
    const [isLoading, setIsLoading] = useState(true);
    
    // const fetchProfileData = () => {
    //     axiosConfig.get('profile')
    //         .then(({data}) => {
    //             setProfileDetail(data.data);
    //         })
    //         .then((error) => {
    //             toast.error(error.response.data.message);
    //             console.log(error)
    //         })
    //         .finally(() => {
    //             setIsLoading(false);
    //         })
    // }
    
    useEffect(() => {
        fetchProfileData((data) => {
            setProfileDetail(data)
        }, () => {
            setIsLoading(false)
        })
    }, [])
    
    const handleDownload = (filename) => {
        handleDownloadFile('file/download', { fileName: filename})
            .then(r => {
                // console.log("success");
            })
            .catch(() => {
                alert('Kesalahan mendownload file');
            })
            .finally(() => {
                
            })
    }
    
    return(
        <AdminWidget>
            {isLoading && (
                <SpinnerLoading textColor={'white'} text={'Fetching profile detail'} />
            )}
            <div>
                <div className="px-4 sm:px-0">
                    <div className={'flex gap-5 items-center'}>
                        <div>
                            <h3 className="text-base font-bold leading-7 text-gray-900">Biodata</h3>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details</p>
                        </div>
                        <div>
                            <Link to={'/admin/profile/edit'} className={'btn btn-sm bg-green-500 text-white hover:bg-green-700'}>Edit Profile</Link>
                        </div>
                    </div>
                    <img src={profileDetail?.photoUrl} alt={'photo'} className={'w-[256px]'}/>
                </div>
                <div className="mt-6 border-t border-gray-100">
                    <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Full name</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{profileDetail?.fullName}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{profileDetail?.email}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Job</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{profileDetail?.jobName}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Phone</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{profileDetail?.phone ?? '-'}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Website</dt>
                            <dd className="mt-1 text-sm leading-6 text-blue-700 sm:col-span-2 sm:mt-0"><a
                                target='_blank' href={profileDetail?.websiteUrl}>{profileDetail?.websiteUrl ?? '-'}</a>
                            </dd>
                            {/*{!profileDetail.websiteUrl && (*/}
                            {/*    <dd className="mt-1 text-sm leading-6 text-blue-700 sm:col-span-2 sm:mt-0"><span>-</span></dd>*/}
                            {/*)}*/}
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Caption Hero</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {profileDetail?.captionHero}
                            </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Caption Contact</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {profileDetail?.captionContact}
                            </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">CV</dt>
                            <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                        <div className="flex w-0 flex-1 items-center">
                                            {/*<PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />*/}
                                            <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                <span className="truncate font-medium">{profileDetail?.cv}</span>
                                                {/*<span className="flex-shrink-0 text-gray-400">2.4mb</span>*/}
                                            </div>
                                        </div>
                                        <div className="ml-4 flex-shrink-0">
                                            <button onClick={() => handleDownload(profileDetail?.cv)}
                                                    className="font-medium text-indigo-600 hover:text-indigo-500">
                                                Download
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">CV Doc</dt>
                            <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                        <div className="flex w-0 flex-1 items-center">
                                            {/*<PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />*/}
                                            <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                <span className="truncate font-medium">{profileDetail?.cvDoc ?? "No file"}</span>
                                                {/*<span className="flex-shrink-0 text-gray-400">2.4mb</span>*/}
                                            </div>
                                        </div>
                                        <div className="ml-4 flex-shrink-0">
                                            <button onClick={() => handleDownload(profileDetail?.cvDoc)} disabled={!profileDetail?.cvDoc}
                                                    className="font-medium text-indigo-600 hover:text-indigo-500">
                                                Download
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </AdminWidget>
    )
}
export default Profile